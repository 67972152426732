.primary-button {
    background-color: rgb(211, 97, 9);
    border: none;
    border-radius: 50px;
    color: white;
    padding: 14px 30px;
    cursor: pointer;
    transition: background-color 300ms;
    font-weight: bold;
    font-size: 16px;
}

.primary-button:hover {
    background-color: rgb(168, 78, 10);
}

.secondary-button {
    background-color: #d8ab23;
    border: none;
    border-radius: 20px;
    color: white;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    transition: background-color 300ms;
}

.secondary-button:hover {
    background-color: #bb972a;
}